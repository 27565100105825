import {
  isEmpty,
  pick,
  omit,
  reduce,
  isArray,
  isString,
  find,
  get,
  includes,
} from 'lodash';
import { getValues } from 'utility/urlUtils';

export const CRITERIA_QUERY_VALUES = [
  'od',
  'od_min',
  'od_max',
  'weight_per_foot',
  'weight_per_foot_min',
  'weight_per_foot_max',
  'min_yield',
  'min_yield_min',
  'min_yield_max',
  'metallurgy',
  'metallurgy_group',
  'metallurgy_other',
  'end_finish',
  'end_finish_other',
  'category',
  'brand',
  'range',
  'range_id',
  'end_finish_licensee',
  'end_finish_category',
  'end_finish_thread_type',
];
export const ASSET_MANAGEMENT_CRITERIA_QUERY_VALUES = [
  'od',
  'od_min',
  'od_max',
  'weight_per_foot',
  'weight_per_foot_min',
  'weight_per_foot_max',
  'min_yield',
  'min_yield_min',
  'min_yield_max',
  'pipe_alloy',
  'metallurgy_group',
  'metallurgy_other',
  'pipe_end_finish',
  'end_finish_other',
  'category',
  'brand',
  'range',
  'range_id',
  'end_finish_licensee',
  'end_finish_category',
  'end_finish_thread_type',
];
export const CRITERIA_ARRAY_TYPES = [
  'end_finish',
  'end_finish_category',
  'end_finish_licensee',
  'end_finish_thread_type',
  'end_finish_other',
  'metallurgy',
  'metallurgy_group',
  'metallurgy_other',
];

/** It's possible to call this function before window is defined. The function call should be wrapped in a canUseDOM check when used to retrieve initial values.
 */
export function getCriteriaParams() {
  const criteria = pick(
    getValues({
      arrayFunction: ({ v }) => (CRITERIA_ARRAY_TYPES.indexOf(v) ? [v] : v),
    }),
    CRITERIA_QUERY_VALUES
  );
  return isEmpty(criteria) ? undefined : criteria;
}

// CRITERIA IS DEFINED AS SINGULAR RFQ ITEM - WHERE INQUIRY THERE ARE RANGES, CRITERIA THERE IS NOT

export function transformValuesFromCriteria(criteria) {
  // TAKE CRITERIA AND MAKE FORM API
  function determineEndFinish() {
    if (isArray(criteria.end_finish)) {
      return criteria.end_finish;
    }
    if (criteria.end_finish) {
      return [criteria.end_finish];
    }
    return undefined;
  }

  function determineMetallurgy() {
    if (isArray(criteria.metallurgy)) {
      return criteria.metallurgy;
    }
    if (criteria.metallurgy) {
      return [criteria.metallurgy];
    }
    return undefined;
  }

  return {
    od: criteria.od,
    category: criteria.category,
    min_yield: criteria.min_yield,
    end_finish: determineEndFinish(),
    metallurgy: determineMetallurgy(),
    inside_diameter: criteria.inside_diameter,
    weight_per_foot: criteria.weight_per_foot,
    range: criteria.range,
  };
}

export function prepareItemsFromCriteria(criteria) {
  // TAKE FORM WIDGET DATA AND CREATE CRITERIA

  // PLACES USED
  // Listings Management
  // RFQ ITEM SUPPLY EVENTAULLY -- TODO
  function stripArray(v) {
    if (isArray(v) && v[0]) {
      return v[0];
    }
    if (isArray(v) && !v[0]) {
      return undefined;
    }
    return v;
  }

  return {
    category: criteria.category,
    od: criteria.od,
    weight_per_foot: criteria.weight_per_foot,
    metallurgy_other: stripArray(criteria.metallurgy_other),
    metallurgy: stripArray(criteria.metallurgy),
    metallurgy_group: stripArray(criteria.metallurgy_group),
    min_yield: criteria.min_yield,
    end_finish_other: stripArray(criteria.end_finish_other),
    end_finish: stripArray(criteria.end_finish),
    range: criteria.range,
    range_id: criteria.range,
  };
}

export function generateCriteriaFromInventoryItem(item, meta) {
  // TAKE INVENTORY ITEM AND GENERATE CRITERIA
  // Used for custom listing
  const blacklistedValues = [
    'brand',
    'brand_id',
    'footage',
    'joints',
    'total_weight',
    'warehouse_name',
    'latitude',
    'longitude',
    'locality',
    'administrative_area_level_1',
    'administrative_area_level_2',
    'country_short',
    'mill_cost',
    'item_detail_slug',
    'type',
    'status',
    'type_status',
  ];
  const options = reduce(
    item,
    (accum, value, key) => {
      const _accum = accum;
      if (!includes(blacklistedValues, key)) {
        _accum[key] = value;
      }
      return _accum;
    },
    {}
  );

  // TODO TODO ____________________________________________ BELOW IS TEMPORARY
  options.category_name = options.category;
  options.category = options.category_id || options.category;
  delete options.category_id;

  options.metallurgy_name = options.metallurgy;
  options.metallurgy = options.metallurgy_id || options.metallurgy;
  delete options.metallurgy_id;

  options.end_finish_name = options.end_finish;
  options.end_finish = options.end_finish_id || options.end_finish;
  delete options.end_finish_id;

  options.metallurgy_group_name = options.metallurgy_group;
  options.metallurgy_group =
    options.metallurgy_group_id || options.metallurgy_group;
  delete options.metallurgy_group_id;

  options.end_finish_licensee_name = options.end_finish_licensee;
  options.end_finish_licensee =
    options.end_finish_licensee_id || options.end_finish_licensee;
  delete options.end_finish_licensee_id;

  options.range_name = options.range;
  options.range = options.range_id || options.range;
  delete options.range_id;

  if (options.range && isString(options.range)) {
    const rangeIfFromString = find(meta.result.range, { name: options.range });
    options.range = get(rangeIfFromString, 'id', '');
  }

  return options;
}

export function generateCriteriaFromAssetManagementInventoryItem(item) {
  const category = item.category_s;
  const metallurgy = item.pipe_alloy_s;
  const endFinish = item.pipe_end_finish_s;
  const range = item.pipe_range_length_s;

  const criteria = {
    ...item,
    category,
    od: item.od_pf,
    weight_per_foot: item.weight_per_foot_pf,
    min_yield: item.pipe_ys_pi,
    metallurgy,
    end_finish: endFinish,
    range,
  };
  return criteria;
}

export function generateCriteriaQueryFilters(rfqitem) {
  // OD + Weight Per Foot + Metallurgy Group
  // Used in Search
  // Searching in Matching Inventory Filters
  // Filter products from categories
  // Searching when creating new listing
  let options = pick(rfqitem, CRITERIA_QUERY_VALUES);
  if (options.weight_per_foot_min === options.weight_per_foot_max) {
    options = omit(options, ['weight_per_foot_min', 'weight_per_foot_max']);
  }

  options.range = options.range_id || options.range;
  delete options.range_id;

  if (!options.range) delete options.range;

  return options;
}

export function generateCustomItemCriteria(rfqitem) {
  // Save a custom item in supply assessment
  let options = pick(rfqitem, [
    'od',
    'od_min',
    'od_max',
    'weight_per_foot',
    'weight_per_foot_min',
    'weight_per_foot_max',
    'min_yield',
    'min_yield_min',
    'min_yield_max',
    'metallurgy',
    'metallurgy_group',
    'metallurgy_other',
    'end_finish',
    'end_finish_other',
    'category',
    'brand',
    'range',
    'range_id',
  ]);
  if (options.weight_per_foot_min === options.weight_per_foot_max) {
    options = omit(options, ['weight_per_foot_min', 'weight_per_foot_max']);
  }

  options.range = options.range_id || options.range;
  delete options.range_id;

  return options;
}
