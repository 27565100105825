import * as Sentry from '@sentry/react';

const URL_MAPPER_PRE = {
  RFQ: 'dashboard/opportunities',
  MRFQ: 'dashboard/manufacturing-opportunities',
  SupplyOpportunity: 'dashboard/supply/group',
  SupplyOpportunityItem: 'dashboard/supply-item',
  MarketComps: 'dashboard/market-comparables',
  AuthUser: 'dashboard/users',
  Company: 'dashboard/companies',
  Campaign: 'dashboard/campaigns',
  Engagement: 'dashboard/engagements',
  AttributeMetaValue: 'dashboard/attributes',
  CampaignRecipients: 'dashboard/campaigns',
  AttributeMetaItemValue: 'dashboard/attributes',
  AttributeMetaSubCategory: 'settings/attributes',
  AttributeMetaCategory: 'settings/attributes',
  ListingPage: 'dashboard/listings',
};

const URL_MAPPER_POST = {
  RFQ: 'information',
  MRFQ: 'overview',
  SupplyOpportunity: 'details',
  SupplyOpportunityItem: 'details',
  MarketComps: 'overview',
  AuthUser: 'profile',
  Company: 'details',
  Campaign: 'details',
  Engagement: 'interactions',
  AttributeMetaValue: 'details',
  CampaignRecipients: 'recipients',
  AttributeMetaItemValue: 'items',
  AttributeMetaSubCategory: '',
  AttributeMetaCategory: '',
  ListingPage: 'preview',
};

const NO_ID = {
  AttributeMetaSubCategory: true,
  AttributeMetaCategory: true,
};

const USE_FOREIGN_ID = {
  RFQ: false,
  MRFQ: false,
  SupplyOpportunity: false,
  SupplyOpportunityItem: false,
  MarketComparables: false,
  AuthUser: false,
  Company: false,
  Campaign: false,
  Engagement: false,
  AttributeMetaValue: false,
  CampaignRecipients: true,
  AttributeMetaItemValue: true,
  ListingPage: false,
};

const A_ARTICLE = 'a';
const AN_ARTICLE = 'an';

export const userReadableObjectTypes = {
  RFQ: {
    name: 'Opportunity',
    article: AN_ARTICLE,
  },
  MRFQ: {
    name: 'Manufacturing Opportunity',
    article: A_ARTICLE,
  },
  SupplyOpportunity: {
    name: 'Supply Opportunity',
    article: A_ARTICLE,
  },
  SupplyOpportunityItem: {
    name: 'Supply Opportunity Item',
    article: A_ARTICLE,
  },
  MarketComparables: {
    name: 'Market Comparable',
    article: A_ARTICLE,
  },
  AuthUser: {
    name: 'Contact',
    article: A_ARTICLE,
  },
  Company: {
    name: 'Company',
    article: A_ARTICLE,
  },
  Campaign: {
    name: 'Campaign',
    article: A_ARTICLE,
  },
  Engagement: {
    name: 'Engagement',
    article: AN_ARTICLE,
  },
  AttributeMetaValue: {
    name: 'Attribute',
    article: AN_ARTICLE,
  },
  CampaignRecipients: {
    name: 'Campaign Recipient',
    article: A_ARTICLE,
  },
  AttributeMetaItemValue: {
    name: 'Attribute',
    article: AN_ARTICLE,
  },
  ListingPage: {
    name: 'Listing',
    article: A_ARTICLE,
  },
};

// Generate urls for notification objects
function objectToUrl({ objectType, objectId, foreignId }) {
  if (!URL_MAPPER_PRE[objectType]) {
    Sentry.captureMessage(
      `Notification generated for unknown object type : '${objectType}''`
    );
    return '/dashboard';
  }

  let url = `/${URL_MAPPER_PRE[objectType]}/`;
  if (!NO_ID[objectType]) {
    url += USE_FOREIGN_ID[objectType] ? foreignId : objectId;
  }

  if (URL_MAPPER_POST[objectType]) {
    url += `/${URL_MAPPER_POST[objectType]}`;
  }

  return url;
}

export default objectToUrl;
