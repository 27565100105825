import {
  ColDef,
  GetDetailRowData,
  SetFilterValuesFunc,
  SetFilterValuesFuncParams,
  ValueFormatterFunc,
} from 'ag-grid-community';
import {
  find,
  map,
  isNaN,
  includes,
  isArray,
  isEqual,
  size,
  omit,
  upperFirst,
  cloneDeep,
} from 'lodash';
import {
  PSAgGridFilterModelSet,
  PSAgGridFilterModelNumber,
  PSAgGridFilterModelType,
  AgGridResponse,
} from 'types/aggrid.ts';

import { PSAgGridInternalContext } from 'types/agGridInternalContext.ts';
import { CriteriaMap, FacetMap, Facets, ItemFilters } from 'types/types.ts';
import fetchJson from 'utility/fetchJson';
import { getValues, setValues } from 'utility/urlUtils';
import { priorityIdToNameMap } from 'utility/staticMap.ts';
import { BASE } from '../constants';

export type Params = {
  success: Function;
  value: string | number;
  api: {
    destroyCalled: boolean;
  };
};

type FieldName = string;

export function getFilterValues(
  params: Params,
  fieldName: FieldName,
  facetMap: FacetMap,
  facets: Facets
) {
  const _fieldName = facetMap[fieldName]?.facet || fieldName;
  const buckets = facets?.[_fieldName]?.buckets;
  const filterValues = (!!buckets && map(buckets, 'val')) || [];
  if (
    facets?.[_fieldName]?.missing &&
    facets?.[_fieldName]?.missing.count > 0
  ) {
    filterValues.push('Missing');
  }
  // Checking for destroyCalled prevents an error: Virtual list has not been created
  if (filterValues && !params.api.destroyCalled) {
    params.success(filterValues);
  }
}

export function formatFilterValues(
  params: Params,
  fieldName: FieldName,
  facetMap: FacetMap,
  facets: Facets
) {
  let searchValue;
  if (params.value === 'true') {
    searchValue = true;
  } else if (params.value === 'false') {
    searchValue = false;
  } else if (!isNaN(Number(params.value))) {
    searchValue = Number(params.value);
  } else {
    searchValue = params.value;
  }
  const _fieldName = facetMap[fieldName]?.facet || fieldName;
  const buckets = facets?.[_fieldName]?.buckets;
  const bucket = !!buckets && find(buckets, ['val', searchValue]);
  let bucketValue = null;
  if (bucket?.name) {
    bucketValue = bucket.name;
  } else if (bucket?.val === true || bucket?.val === false) {
    bucketValue = bucket.val.toString();
  } else if (bucket?.val) {
    bucketValue = bucket.val;
  } else {
    bucketValue = 'No Label';
  }

  if (params.value === 'Missing') {
    return `${params.value} (${facets?.[_fieldName]?.missing.count})`;
  }
  if (bucket) {
    return `${
      facetMap[fieldName]?.buckets?.filterLabels?.[bucketValue] || bucketValue
    } (${bucket.count})`;
  }
  return params.value;
}

export function detectGroupRow(data: unknown) {
  const symbols = Object.getOwnPropertySymbols(data);
  return includes(map(symbols, 'description'), 'childCount');
}

type TempRangeObject = {
  min:
    | number
    | string
    | {
        value: number | string;
      };
  max:
    | number
    | string
    | {
        value: number | string;
      };
};

type FilterData = {
  operator: {
    value: string;
  };
  value: {
    value: string | number | TempRangeObject | {};
    min?: {
      value: string | number;
    };
    max?: {
      value: string | number;
    };
  };
  selectedFilters: {
    groups: {};
  };
};

type HandleChangeFiltersOptions = {
  itemFilters: ItemFilters;
  updateItemFilters: Function;
  setFilters: Function;
  criteriaMap: CriteriaMap;
  forwardedParams: {};
};

export function handleChangeFilters(
  type: string,
  data: FilterData,
  options: HandleChangeFiltersOptions
) {
  const { itemFilters, updateItemFilters, setFilters, forwardedParams } =
    options;

  const currentOperatorValue = itemFilters[type]?.operator?.value;
  const newOperator = data?.operator;
  if (currentOperatorValue !== newOperator?.value) {
    // If we change the Equals/Range operators, we need to update the form and value
    updateItemFilters(type, { operator: newOperator, value: data.value });
  } else {
    let fieldName = type;
    const isRange = newOperator?.value === 'range';
    const value = isArray(data.value)
      ? map(data.value, 'value')
      : data.value?.value || undefined;
    let minValue;
    let maxValue;
    if (isRange) {
      minValue = data.value?.min?.value;
      maxValue = data.value?.max?.value;
    }
    const groupValue = isArray(data?.selectedFilters?.groups)
      ? map(data?.selectedFilters?.groups, 'value')
      : data?.selectedFilters?.groups || undefined;
    let finalValue = value;
    if (type === 'alloy') {
      const previousAlloyGroupValue = isArray(
        itemFilters.alloy.selectedFilters?.groups
      )
        ? map(itemFilters.alloy.selectedFilters?.groups, 'value')
        : itemFilters.alloy.selectedFilters?.groups || undefined;
      if (!isEqual(previousAlloyGroupValue, groupValue)) {
        fieldName = 'metallurgy_group';
        finalValue = groupValue;
      } else {
        fieldName = 'metallurgy';
        finalValue = value;
      }
    }

    // Update querystring
    const querystring = getValues();
    let newQuerystring;
    // Prevents the querystring from becoming out of sync with alloys and groups - just set them both every time one changes
    if (type === 'alloy') {
      newQuerystring = {
        ...querystring,
        metallurgy: isArray(value) && size(value) ? value : undefined,
        metallurgy_group: size(groupValue) ? groupValue : undefined,
      };
    } else if (isRange) {
      newQuerystring = {
        ...querystring,
        [`${fieldName}_min`]: minValue,
        [`${fieldName}_max`]: maxValue,
      };
    } else {
      newQuerystring = {
        ...querystring,
        [fieldName]: finalValue,
      };
    }
    setValues(newQuerystring);

    // Update Item Filters fields
    let updatePayload;
    if (fieldName === 'metallurgy_group') {
      updatePayload = {
        selectedFilters: {
          groups: data.selectedFilters.groups,
        },
      };
    } else {
      updatePayload = {
        value: data.value,
      };
    }
    updateItemFilters(type, updatePayload);

    // Update global filters
    let changedFilters;
    if (type === 'alloy') {
      changedFilters = {
        metallurgy: value,
        metallurgy_group: groupValue,
      };
    } else if (isRange) {
      changedFilters = {
        [`${fieldName}_min`]: minValue,
        [`${fieldName}_max`]: maxValue,
      };
    } else {
      changedFilters = {
        [fieldName]: value,
      };
    }
    setFilters(changedFilters, forwardedParams);
  }
}

export const sortAlphanumerics = (
  a: string | number,
  b: string | number
): number => {
  const aNum = Number(a);
  const bNum = Number(b);

  if (!isNaN(aNum) && !isNaN(bNum)) {
    return aNum - bNum;
  }
  return new Intl.Collator('default', { numeric: true }).compare(
    a as string,
    b as string
  );
};

export const generateAgGridFieldValuesGetter =
  <Fields = Record<string, unknown>>(
    field: Extract<keyof Fields, string>
  ): SetFilterValuesFunc =>
  (params: SetFilterValuesFuncParams): void => {
    const context = params.context.current as PSAgGridInternalContext;

    if (context.forcedRemoteSetFilterValuesToReturn) {
      /** We use this when filters are set programatically. In this case we assume that
       * we know what we are doing and we don't want to fetch the values from the server.
       * So to optimize the process and not call backend for unecessary data, we will just
       * return values that has been forced.
       *
       * Additionally we need to return one extra value. When AgGrid get's all possible
       * values for a field with Set Filter, it will compare what was selected
       * in Set Filter with what is available.
       * If the user has selected all values that are available, then AgGrid
       * will clear the filter 🤯.
       * To prevent this, we need to return one extra value to trick AgGrid into
       * thinking that there are more values available, so AgGrid will not clear
       * the filter. */
      const returnValues = context.forcedRemoteSetFilterValuesToReturn.length
        ? [...context.forcedRemoteSetFilterValuesToReturn, '_ONE_EXTRA_VALUE_']
        : [];
      params.success(returnValues);
      return;
    }

    if (!context.solrCore || !context.documentType) {
      console.error('Solr core and/or document type is not set.');
      params.success([]);
      return;
    }

    const { filterModel, filterModelOrOperands, extraQuery } =
      context.responseFilterModelContext?.getFilterModels() || {};

    const fieldFilterModel = filterModel?.[field] as PSAgGridFilterModelSet;

    let filterModelToUse;
    if (filterModel && fieldFilterModel?.limitedValues?.length) {
      /** We want to limit the values to the ones that were specified in
       * `limitedValues`. */
      fieldFilterModel.values = fieldFilterModel.limitedValues;
      filterModelToUse = filterModel;
    } else {
      /** Otherwise, we want to omit current field from the filter, so the user
       * can see all available values. */
      filterModelToUse = omit(filterModel, [field]);
    }

    fetchJson(`${BASE}/api/aggrid/solr_field_distict_values/`, {
      method: 'POST',
      body: JSON.stringify({
        core: context.solrCore,
        document_type: context.documentType,
        field,
        filter_model: filterModelToUse,
        filter_model_or_operands: filterModelOrOperands,
        extra_query: extraQuery,
      }),
    })
      .then((result) => {
        const sortedResult = result.sort(sortAlphanumerics);

        params.success(sortedResult);
      })
      .catch(() => params.success([]));
  };

export const getAgGridRemoteSetFilterParams = <Column extends string>(
  column: Column,
  filterValueFormatter?: ValueFormatterFunc
): ColDef['filterParams'] => ({
  values: generateAgGridFieldValuesGetter(column),
  valueFormatter: filterValueFormatter,
  suppressSorting: true,
  refreshValuesOnOpen: true,
  caseSensitive: true,
});

export const filterParamsValueFormatters = {
  upperFirst: ({ value }) => (value ? upperFirst(value) : value),
  upperOnlyFirst: ({ value }) =>
    value
      ? value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
      : value,
  titleCase: ({ value }) =>
    value ? value.toLowerCase().replace(/\b\w/g, upperFirst) : value,
  priorityIdToName: ({ value }) =>
    priorityIdToNameMap[value as number] || value,
} satisfies Record<string, ValueFormatterFunc>;

export const EMPTY_AGGRID_REQUEST = {
  filters: {
    agGridData: { filterModel: {}, startRow: 0, endRow: 100 },
  },
};

export const getLimitedSetFilterModel = (
  limitedValues: PSAgGridFilterModelSet['values'],
  userSetValues: PSAgGridFilterModelSet['values'] | undefined
): PSAgGridFilterModelSet => ({
  values:
    userSetValues?.filter((value) => limitedValues.includes(value)) ||
    limitedValues,
  filterType: PSAgGridFilterModelType.set,
  limitedValues,
});

export const generateMasterDetailConfig = (
  colDefs: ColDef[],
  getData: GetDetailRowData
) => ({
  detailGridOptions: {
    columnDefs: colDefs,
    pagination: true,
    paginationPageSize: 10,
  },
  getDetailRowData: getData,
  refreshStrategy: 'rows',
});

export const defaultColDef: ColDef = {
  floatingFilter: true,
  sortable: true,
  resizable: true,
  enableRowGroup: false,
  valueFormatter: ({ value }) => value || '--',
};

export const createFilterModel = {
  set: (values: string[]): PSAgGridFilterModelSet => ({
    filterType: PSAgGridFilterModelType.set,
    values,
  }),
  number: (number: number): PSAgGridFilterModelNumber => ({
    filterType: PSAgGridFilterModelType.number,
    type: 'equals',
    filter: number,
  }),
  numberRange: (min: number, max: number): PSAgGridFilterModelNumber => ({
    filterType: PSAgGridFilterModelType.number,
    type: 'inRange',
    filter: min,
    filterTo: max,
  }),
  numberGreaterThan: (number: number): PSAgGridFilterModelNumber => ({
    filterType: PSAgGridFilterModelType.number,
    type: 'greaterThan',
    filter: number,
  }),
  numberGreaterThanOrEqual: (number: number): PSAgGridFilterModelNumber => ({
    filterType: PSAgGridFilterModelType.number,
    type: 'greaterThanOrEqual',
    filter: number,
  }),
};

export function rewriteAgGridResponseToRemoveSolrFieldsPostfixes<
  T extends Record<string, unknown>
>(response: AgGridResponse<T>): AgGridResponse<Record<string, unknown>> {
  if (response && response?.docs) {
    const newResponse = cloneDeep(response);
    for (let i = 0; i < newResponse.docs.length; i += 1) {
      const doc = newResponse.docs[i];
      Object.keys(doc).forEach((key) => {
        const rewrittenKey = key.replace(/_[a-z]{1,3}$/, '');
        if (rewrittenKey !== key) {
          // @ts-ignore
          doc[rewrittenKey] = doc[key];
          delete doc[key];
        }
      });
    }
    return newResponse;
  }
  return response;
}
