import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { startCase, get } from 'lodash';
import Spinner from 'components/Spinner/Spinner';
import Loadable from 'react-loadable';
import authOnEnter from 'utility/authOnEnter';
import {
  isFacilitator,
  isAdmin,
  isPartner,
  isEndUser,
  isBetaTester,
} from 'utility/hasAccount';
import { Route, Redirect } from 'react-router';
import { locationShape } from 'propTypesObjects';

export const hardRedirectRoute = (route) =>
  function HardRedirect() {
    useEffect(() => {
      window.location = route;
    }, []);
    return <div />;
  };

export const requireAuth = (Component) => {
  function AuthWrapper({ location, ...props }) {
    const isAuthenticated = authOnEnter();
    const hasRole = isEndUser() || isPartner() || isFacilitator() || isAdmin();
    if (isAuthenticated && hasRole) {
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component location={location} {...props} />;
    }
    return (
      <RedirectWithStatus
        status={302}
        to={{
          pathname: `/login`,
          state: { from: location, next: get(location, 'pathname') },
        }}
      />
    );
  }

  AuthWrapper.propTypes = {
    // LINT OVERRIDE #2
    // https://github.com/remix-run/react-router/issues/6177
    // External object
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  };

  return AuthWrapper;
};

export const redirectFacilitator = (Component) => {
  function RedirectFacilitatorWrapper({ location, ...props }) {
    return isFacilitator() ? (
      <RedirectWithStatus
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    ) : (
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component location={location} {...props} />
    );
  }

  RedirectFacilitatorWrapper.propTypes = {
    // LINT OVERRIDE #2
    // https://github.com/remix-run/react-router/issues/6177
    // External object
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  };

  return RedirectFacilitatorWrapper;
};

export const requireAuthWithoutRole = (Component) => {
  function AuthWithoutRoleWrapper({ location, ...props }) {
    return authOnEnter() ? (
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component location={location} {...props} />
    ) : (
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }

  AuthWithoutRoleWrapper.propTypes = {
    // LINT OVERRIDE #2
    // https://github.com/remix-run/react-router/issues/6177
    // External object
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  };

  return AuthWithoutRoleWrapper;
};

export const requireFacilitatorOrAdmin = (Component) => {
  function FacilitatorOrAdminWrapper({ location, ...props }) {
    return authOnEnter() && (isFacilitator() || isAdmin()) ? (
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component location={location} {...props} />
    ) : (
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );
  }

  FacilitatorOrAdminWrapper.propTypes = {
    // LINT OVERRIDE #2
    // https://github.com/remix-run/react-router/issues/6177
    // External object
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  };

  return FacilitatorOrAdminWrapper;
};

export const requireFacilitatorOrAdminOrPartner = (Component) => {
  function FacilitatorOrAdminOrPartnerWrapper({ location, ...props }) {
    return authOnEnter() && (isFacilitator() || isAdmin() || isPartner()) ? (
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component location={location} {...props} />
    ) : (
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );
  }

  FacilitatorOrAdminOrPartnerWrapper.propTypes = {
    location: locationShape,
  };

  return FacilitatorOrAdminOrPartnerWrapper;
};

export const requireFacilitatorOrAdminForBeta = (Component) => {
  function FacilitatorOrAdminForBetaWrapper({ location, ...props }) {
    if (authOnEnter() && (isFacilitator() || isAdmin() || isBetaTester())) {
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Component location={location} {...props} />;
    }
    return (
      <RedirectWithStatus
        status={302}
        to={{
          pathname: `/login`,
          state: { from: location, next: get(location, 'pathname') },
        }}
      />
    );
  }

  FacilitatorOrAdminForBetaWrapper.propTypes = {
    // LINT OVERRIDE #2
    // https://github.com/remix-run/react-router/issues/6177
    // External object
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  };

  return FacilitatorOrAdminForBetaWrapper;
};

export const requireFacilitator = (Component) => {
  function FacilitatorWrapper({ location, ...props }) {
    return authOnEnter() && isFacilitator() ? (
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component location={location} {...props} />
    ) : (
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );
  }

  FacilitatorWrapper.propTypes = {
    // LINT OVERRIDE #2
    // https://github.com/remix-run/react-router/issues/6177
    // External object
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  };

  return FacilitatorWrapper;
};

export const requireFacilitatorOrPartner = (Component) => {
  function FacilitatorOrPartnerWrapper({ location, ...props }) {
    return authOnEnter() && (isFacilitator() || isPartner()) ? (
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component location={location} {...props} />
    ) : (
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );
  }

  FacilitatorOrPartnerWrapper.propTypes = {
    // LINT OVERRIDE #2
    // https://github.com/remix-run/react-router/issues/6177
    // External object
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  };

  return FacilitatorOrPartnerWrapper;
};

export const requireAdmin = (Component) => {
  function AdminWrapper({ location, ...props }) {
    return authOnEnter() && isAdmin() ? (
      // LINT OVERRIDE #3
      // Component wraps another component
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Component location={location} {...props} />
    ) : (
      <RedirectWithStatus
        status={302}
        to={{
          pathname: '/dashboard',
          state: { from: location },
        }}
      />
    );
  }

  AdminWrapper.propTypes = {
    // LINT OVERRIDE #2
    // https://github.com/remix-run/react-router/issues/6177
    // External object
    // eslint-disable-next-line react/forbid-prop-types
    location: PropTypes.object,
  };

  return AdminWrapper;
};

function RedirectWithStatus({ from, to, status }) {
  return (
    <Route
      render={({ staticContext }) => {
        if (staticContext) {
          staticContext.status = status;
        }
        return <Redirect from={from} to={to} />;
      }}
    />
  );
}

RedirectWithStatus.propTypes = {
  from: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      // LINT OVERRIDE #2
      // https://github.com/remix-run/react-router/issues/6177
      // External object
      // eslint-disable-next-line react/forbid-prop-types
      from: PropTypes.object,
    }),
  }),
  to: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      // LINT OVERRIDE #2
      // https://github.com/remix-run/react-router/issues/6177
      // External object
      // eslint-disable-next-line react/forbid-prop-types
      from: PropTypes.object,
    }),
  }),
  status: PropTypes.number,
};

const error404 = (Component) =>
  function Error404Wrapper(props) {
    return (
      <Route
        render={({ staticContext }) => {
          if (staticContext) {
            staticContext.missed = true;
            staticContext.status = 404;
          }
          // LINT OVERRIDE #3
          // Component wraps another component
          // eslint-disable-next-line react/jsx-props-no-spreading
          return <Component {...props} />;
        }}
      />
    );
  };

function PageLoader() {
  return (
    <div style={{ minHeight: '100vh', display: 'flex', alignItems: 'center' }}>
      <Spinner />
    </div>
  );
}

export const AsyncPageError = Loadable({
  loader: () =>
    import(/* webpackChunkName: "errorPage" */ './routes/Error/Error'),
  loading: () => <PageLoader />,
  modules: ['Error'],
});

const AsyncCantLogin = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CantLoginChunk" */ './routes/CantLogin/CantLogin'
    ),
  loading: () => <PageLoader />,
  modules: ['AsyncCantLoginChunk'],
});

const AsyncConfirmEmail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ConfirmEmailChunk" */ './routes/ConfirmEmail/ConfirmEmail'
    ),
  loading: () => <PageLoader />,
  modules: ['AsyncConfirmEmailChunk'],
});

const AsyncLogin = Loadable({
  loader: () =>
    import(/* webpackChunkName: "LoginChunk" */ './routes/Login/Login'),
  loading: () => <PageLoader />,
  modules: ['AsyncLoginChunk'],
});

const AsyncAccountCreated = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AccountCreatedChunk" */ './routes/AccountCreated/AccountCreated'
    ),
  loading: () => <PageLoader />,
  modules: ['AsyncAccountCreatedChunk'],
});

const AsyncResetPassword = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ResetPasswordChunk" */ './routes/ResetPassword/ResetPassword'
    ),
  loading: () => <PageLoader />,
  modules: ['AsyncResetPasswordChunk'],
});

const AsyncBlogList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "BlogListChunk" */ './routes/BlogList/BlogList'
    ),
  loading: () => <PageLoader />,
  modules: ['BlogListChunk'],
});

const AsyncPageBlogPostContent = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "BlogPostContentChunk" */ './routes/BlogPostContent/BlogPostContent'
    ),
  loading: () => <PageLoader />,
  modules: ['BlogPostContentChunk'],
});

const AsyncPageSearchWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "search" */ './routes/SearchResults/SearchResults'
    ),
  loading: () => <PageLoader />,
  modules: ['search'],
});

const AsyncPageSearchDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "search" */ './routes/SearchResults/routes/SearchDetails/SearchDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['search'],
});

const AsyncPageGlobalWarehouse = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "search" */ './routes/GlobalWarehouse/GlobalWarehouse'
    ),
  loading: () => <PageLoader />,
  modules: ['search'],
});

const AsyncPageProductGuide = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "search" */ './routes/ProductsPage/ProductsPage'
    ),
  loading: () => <PageLoader />,
  modules: ['search'],
});

const AsyncPageSearchListings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "search" */ './routes/SearchResults/routes/SearchDetails/SearchDetailsListings'
    ),
  loading: () => <PageLoader />,
  modules: ['search'],
});

const AsyncTechnicalResources = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "TechnicalResources" */ './routes/TechnicalResources/TechnicalResources'
    ),
  loading: () => <PageLoader />,
  modules: ['TechnicalResources'],
});

const AsyncPageCategoriesWrapper = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CategoriesChunk2" */ './routes/Categories/Categories'
    ),
  loading: () => <PageLoader />,
  modules: ['CategoriesChunk2'],
});

const AsyncPageCategoryDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CategoriesChunk" */ './routes/Categories/routes/CategoryDetails/CategoryDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['CategoriesChunk'],
});

const AsyncProductPages = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProductsChunk" */ './routes/Products/Products'
    ),
  loading: () => <PageLoader />,
  modules: ['ProductsChunk'],
});

const AsyncListingPages = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ListingsChunk" */ './routes/Products/Listings'
    ),
  loading: () => <PageLoader />,
  modules: ['ListingsChunk'],
});

const AsyncPageConversations = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Conversations" */ './routes/Conversations/Conversations'
    ),
  loading: () => <PageLoader />,
  modules: ['Conversations'],
});

const AsyncPagePartnerManagerConversation = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Conversations" */ './routes/Conversations/PartnerManagerConversation'
    ),
  loading: () => <PageLoader />,
  modules: ['Conversations'],
});

const AsyncPageOpportunities = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Opportunities" */ './routes/Opportunities/Opportunities'
    ),
  loading: () => <PageLoader />,
  modules: ['Opportunities'],
});
const AsyncPageInventory = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Inventory" */ './routes/Inventory/Inventory'),
  loading: () => <PageLoader />,
  modules: ['Inventory'],
});

const AsyncPageCompanies = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Companies" */ './routes/Companies/Companies'),
  loading: () => <PageLoader />,
  modules: ['Companies'],
});

const AsyncPageCompanyDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompanyDetails" */ './routes/CompanyDetails/CompanyDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanyDetails'],
});

const AsyncPageCompanyDetailsOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompanyDetailsOverview" */ './routes/CompanyDetails/routes/CompanyDetailsOverview/CompanyDetailsOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanyDetailsOverview'],
});

const AsyncPageCompanyWishlist = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompanyWishlist" */ './routes/CompanyDetails/routes/CompanyWishlist/CompanyWishlist'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanyWishlist'],
});

const AsyncPageCompanyMessaging = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompanyMessaging" */ './routes/CompanyDetails/routes/CompanyMessaging/CompanyMessaging'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanyMessaging'],
});

const AsyncPageCompanyActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompanyActivity" */ './routes/CompanyDetails/routes/CompanyActivity/CompanyActivity'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanyActivity'],
});

const AsyncPageCompanyEngagements = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompanyEngagements" */ './routes/CompanyDetails/routes/CompanyEngagements/CompanyEngagements'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanyEngagements'],
});

const AsyncPageCompanyActionItems = Loadable({
  loader: () =>
    import(
      './routes/CompanyDetails/routes/CompanyActionItems/CompanyActionItems'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanyEngagements'],
});

const AsyncPageCompanyDemand = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompanyDemand" */ './routes/CompanyDetails/routes/CompanyDemand/CompanyDemand.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanyDemand'],
  render: (loaded, props) => <loaded.CompanyDemand {...props} />,
});

const AsyncPageCompanySupply = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompanySupply" */ './routes/CompanyDetails/routes/CompanySupply/CompanySupply.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanySupply'],
  render: (loaded, props) => <loaded.CompanySupply {...props} />,
});

const AsyncPageProjectManagement = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ProjectManagement" */ './routes/ProjectManagement/ProjectManagement'
    ),
  loading: () => <PageLoader />,
  modules: ['ProjectManagement'],
});

const AsyncPageEngagementsList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EngagementsList" */ './routes/ProjectManagement/routes/EngagementsList/EngagementsList'
    ),
  loading: () => <PageLoader />,
  modules: ['EngagementsList'],
});

const AsyncPageInteractionsList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "InteractionsList" */ './routes/ProjectManagement/routes/InteractionsList/InteractionsList'
    ),
  loading: () => <PageLoader />,
  modules: ['InteractionsList'],
});

const AsyncPageActionItemsList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ActionItemsList" */ './routes/ProjectManagement/routes/ActionItemsList/ActionItemsList'
    ),
  loading: () => <PageLoader />,
  modules: ['ActionItemsList'],
});

const AsyncPageEngagementDetail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EngagementDetail" */ './routes/CRM/EngagementDetail'
    ),
  loading: () => <PageLoader />,
  modules: ['EngagementDetail'],
});
const AsyncPageEngagementInteractions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EngagementInteractions" */ './routes/CRM/routes/EngagementInteractions/EngagementInteractions'
    ),
  loading: () => <PageLoader />,
  modules: ['EngagementInteractions'],
});
const AsyncPageEngagementActionItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EngagementActionItems" */ './routes/CRM/routes/EngagementActionitems/EngagementActionitems'
    ),
  loading: () => <PageLoader />,
  modules: ['EngagementActionitems'],
});
const AsyncPageEngagementMessaging = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "EngagementActionItems" */ './routes/CRM/routes/EngagementMessaging/EngagementMessaging'
    ),
  loading: () => <PageLoader />,
  modules: ['EngagementMessaging'],
});
const AsyncPageEngagementDocuments = Loadable({
  loader: () =>
    import('./routes/CRM/routes/EngagementDocuments/EngagementDocuments'),
  loading: () => <PageLoader />,
  modules: ['EngagementDocuments'],
});

const AsyncPageSupplyInterests = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterests" */ './routes/SupplyInterests/SupplyInterests'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterests'],
});
const AsyncPageSupplyInterestsGroupsAgGrid = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterests" */ './routes/SupplyInterests/routes/SupplyInterestsGroupsAgGrid'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterests'],
});
const AsyncPageSupplyInterestsItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterests" */ './routes/SupplyInterests/routes/SupplyInterestsItems'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterests'],
});

const AsyncPageBlogEditor = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "BlogEditor" */ './routes/BlogEditor/BlogEditor'
    ),
  loading: () => <PageLoader />,
  modules: ['BlogEditor'],
});

const AsyncMarketComparablesMain = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/MarketComparables'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncMarketComparablesDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/MarketComparables/components/MarketComparablesDetails/MarketComparablesDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncMarketComparablesDetailsOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/MarketComparables/routes/MarketCompOverview/MarketCompOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncMarketComparablesDetailsActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/MarketComparables/routes/MarketCompActivity/MarketCompActivity'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncMarketComparablesDetailsMessages = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/MarketComparables/routes/MarketCompMessages/MarketCompMessages'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncMarketComparablesDetailsDocuments = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/MarketComparables/routes/MarketCompDocuments/MarketCompDocuments'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncMarketComparables = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/MarketComparables/MarketComparables'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncRequestHistory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/RequestHistory/RequestHistory'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncOrderHistory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/OrderHistory/OrderHistory'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncPurchaseHistory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/PurchaseHistory/PurchaseHistory'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncQuoteHistory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/QuoteHistory/QuoteHistory'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncScrapHistory = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MarketComparables" */ './routes/MarketComparables/routes/ScrapHistory/ScrapHistory'
    ),
  loading: () => <PageLoader />,
  modules: ['MarketComparables'],
});

const AsyncPageCustomers = Loadable({
  loader: () =>
    import(/* webpackChunkName: "Customers" */ './routes/Customers/Customers'),
  loading: () => <PageLoader />,
  modules: ['Customers'],
});

const AsyncPageCustomersAgGrid = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Customers" */ './routes/Customers/CustomersAgGrid.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['Customers'],
});

const AsyncPagePartnerCustomersTable = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Customers" */ './routes/Customers/PartnerCustomersDynamic'
    ),
  loading: () => <PageLoader />,
  modules: ['Customers'],
});

const AsyncPageCustomersNetworking = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Customers" */ './routes/Customers/routes/CustomersNetworking/CustomersNetworkingAgGrid.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['Customers'],
});

const AsyncPageCampaigns = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "Campaigns" */ './routes/Campaigns/Campaigns.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['Campaigns'],
});

const AsyncPageCampaignDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CampaignDetails" */ './routes/CampaignDetails/CampaignDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['CampaignDetails'],
});

const AsyncPageCampaignDetailsOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CampaignDetailsOverview" */ './routes/CampaignDetails/routes/CampaignDetailsOverview/CampaignDetailsOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['CampaignDetailsOverview'],
});

const AsyncPageCampaignRecipients = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CampaignRecipients" */ './routes/CampaignDetails/routes/CampaignRecipients/CampaignRecipients.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['CampaignRecipients'],
});

const AsyncPageCampaignPreview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CampaignPreview" */ './routes/CampaignDetails/routes/CampaignPreview/CampaignPreview'
    ),
  loading: () => <PageLoader />,
  modules: ['CampaignPreview'],
});

const AsyncPageCampaignActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CampaignActivity" */ './routes/CampaignDetails/routes/CampaignActivity/CampaignActivity'
    ),
  loading: () => <PageLoader />,
  modules: ['CampaignActivity'],
});

const AsyncPageCampaignMessaging = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CampaignMessaging" */ './routes/CampaignDetails/routes/CampaignMessaging/CampaignMessaging'
    ),
  loading: () => <PageLoader />,
  modules: ['CampaignMessaging'],
});

const AsyncPageCustomerDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "customerPage" */ './routes/CustomerDetails/CustomerDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetails'],
});

const AsyncPageCustomerDetailsAnalytics = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "customerPage" */ './routes/CustomerDetails/routes/CustomerDetailsAnalytics/CustomerDetailsAnalytics'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsAnalytics'],
});

const AsyncPageCustomerDetailsOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "customerPage" */ './routes/CustomerDetails/routes/CustomerDetailsOverview/CustomerDetailsOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsOverview'],
});

const AsyncPageCustomerDetailsWishlist = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "customerPage" */ './routes/CustomerDetails/routes/CustomerDetailsWishlist/CustomerDetailsWishlist'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsWishlist'],
});

const AsyncPageCustomerDetailsActionItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "customerPage" */ './routes/CustomerDetails/routes/CustomerDetailsActionItems/CustomerDetailsActionItems'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsActionItems'],
});

const AsyncPageCustomerDetailsMessages = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "customerPage" */ './routes/CustomerDetails/routes/CustomerDetailsMessages/CustomerDetailsMessages'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsMessages'],
});

const AsyncPageCustomerDetailsEngagements = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "customerPage" */ './routes/CustomerDetails/routes/CustomerDetailsEngagements/CustomerDetailsEngagements'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsEngagements'],
});

const AsyncPageCustomerDetailsActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CustomerDetailsActivity" */ './routes/CustomerDetails/routes/CustomerDetailsActivity/CustomerDetailsActivity'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsActivity'],
});

const AsyncPageCustomerDetailsDemand = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "customerPage" */ './routes/CustomerDetails/routes/CustomerDetailsDemand/CustomerDetailsDemand.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsDemand'],
  render: (loaded, props) => <loaded.CustomerDetailsDemand {...props} />,
});

const AsyncPageCustomerDetailsSupply = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "customerPage" */ './routes/CustomerDetails/routes/CustomerDetailsSupply/CustomerDetailsSupply.tsx'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsSupply'],
  render: (loaded, props) => <loaded.CustomerDetailsSupply {...props} />,
});
const AsyncPageCustomerDetailsInteraction = Loadable({
  loader: () =>
    import(
      './routes/CustomerDetails/routes/CustomerDetailsInteraction/CustomerDetailsInteraction'
    ),
  loading: () => <PageLoader />,
  modules: ['CustomerDetailsInteractions'],
});

const AsyncPageCreateAccount = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CreateAccountChunk" */ './routes/CreateAccount/CreateAccount'
    ),
  loading: () => <PageLoader />,
  modules: ['CreateAccount'],
});

const AsyncAccountSubscriptions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AccountSubscriptionsChunk" */ './routes/AccountSubscriptions/AccountSubscriptions'
    ),
  loading: () => <PageLoader />,
  modules: ['AccountSubscriptionsChunk'],
});

const AsyncPageLanding = Loadable({
  loader: () =>
    import(/* webpackChunkName: "LandingChunk" */ './routes/Landing/Landing'),
  loading: () => <PageLoader />,
  modules: ['LandingChunk'],
});

const AsyncPageWelcomeSuppliers = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "WelcomeSuppliersChunk" */ './routes/Landing/WelcomeSuppliers'
    ),
  loading: () => <PageLoader />,
  modules: ['WelcomeSuppliersChunk'],
});

const AsyncRequestForQuoteLanding = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RequestItemLanding" */ './routes/RequestForQuoteLanding/RequestForQuoteLanding'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteLanding'],
});

const AsyncPageForSellers = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersChunk" */ './routes/ForSellers/ForSellers'
    ),
  loading: () => <PageLoader />,
  modules: ['SellersChunk'],
});

const AsyncPageForBuyers = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SellersChunk" */ './routes/ForBuyers/ForBuyers'
    ),
  loading: () => <PageLoader />,
  modules: ['SellersChunk'],
});

const AsyncPageCompanyWhyPipeSearch = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "CompanyWhyPipeSearch" */ './routes/CompanyWhyPipeSearch/CompanyWhyPipeSearch'
    ),
  loading: () => <PageLoader />,
  modules: ['CompanyWhyPipeSearch'],
});

const AsyncPageContact = Loadable({
  loader: () =>
    import(/* webpackChunkName: "ContactChunk" */ './routes/Contact/Contact'),
  loading: () => <PageLoader />,
  modules: ['ContactChunk'],
});

// ADMIN PAGES

const AsyncAdminPanel = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardOne" */ './routes/AdminPanel/AdminPanel'
    ),
  loading: () => <PageLoader />,
  modules: ['AdminPanel'],
});

const AsyncDashboard = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardOne" */ './routes/Dashboard/Dashboard'
    ),
  loading: () => <PageLoader />,
  modules: ['Dashboard'],
});

const AsyncDashboardFacilitatorAttributes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardOne" */ './routes/Dashboard/routes/FacilitatorAttributes'
    ),
  loading: () => <PageLoader />,
  modules: ['Dashboard'],
});

const AsyncDashboardFacilitatorPerformance = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardOne" */ './routes/Dashboard/routes/FacilitatorPerformance'
    ),
  loading: () => <PageLoader />,
  modules: ['Dashboard'],
});

const AsyncDashboardRecentItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardOne" */ './routes/Dashboard/routes/DashboardRecentItems'
    ),
  loading: () => <PageLoader />,
  modules: ['Dashboard'],
});

const AsyncPageMessages = Loadable({
  loader: () =>
    import(/* webpackChunkName: "dashboardTwo" */ './routes/Messages/Messages'),
  loading: () => <PageLoader />,
  modules: ['Messages'],
});

const AsyncPageMyRequests = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardOne" */ './routes/MyRequests/MyRequests'
    ),
  loading: () => <PageLoader />,
  modules: ['MyRequests'],
});

const AsyncPageDashboardListings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardTwo" */ './routes/DashboardListings/DashboardListings'
    ),
  loading: () => <PageLoader />,
  modules: ['DashboardListings'],
});
const AsyncPageListingDetail = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardTwo" */ './routes/DashboardListings/ListingDetail'
    ),
  loading: () => <PageLoader />,
  modules: ['ListingDetail'],
});
const AsyncPageListingCampaigns = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardTwo" */ './routes/DashboardListings/routes/ListingCampaigns/ListingCampaigns'
    ),
  loading: () => <PageLoader />,
  modules: ['Campaigns'],
});
const AsyncPageListingMessaging = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardTwo" */ './routes/DashboardListings/routes/ListingMessaging/ListingMessaging'
    ),
  loading: () => <PageLoader />,
  modules: ['Messages'],
});
const AsyncPageListingOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardTwo" */ './routes/DashboardListings/routes/ListingOverview/ListingOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['Overview'],
});
const AsyncPageListingPerformance = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardTwo" */ './routes/DashboardListings/routes/ListingPerformance/ListingPerformance'
    ),
  loading: () => <PageLoader />,
  modules: ['Performance'],
});

const AsyncPageProductsManagement = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardTwo" */ './routes/ProductsManagement/ProductsManagement'
    ),
  loading: () => <PageLoader />,
  modules: ['ProductsManagement'],
});

const AsyncPageWishList = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardThree" */ './routes/WishList/WishList'
    ),
  loading: () => <PageLoader />,
  modules: ['WishList'],
});

const AsyncPageActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardThree" */ './routes/Activity/Activity'
    ),
  loading: () => <PageLoader />,
  modules: ['Activity'],
});

const AsyncPageTechnicalResources = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "dashboardThree" */ './routes/TechnicalResources/TechnicalResources'
    ),
  loading: () => <PageLoader />,
  modules: ['TechnicalResources'],
});

// Demand Opportunity
const AsyncRequestForQuoteDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/RequestForQuoteDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['RequestForQuoteDetails'],
});

const AsyncRfqDetailsInformation = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsInformation/RfqDetailsInformation'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsInformation'],
});

const AsyncRfqDetailsItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsItems/RfqDetailsItems'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsItems'],
});

const AsyncRfqDetailsAssessment = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsAssessment/RfqDetailsAssessment'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsAssessment'],
});

const AsyncRfqDetailsAssesmentInitialFeedback = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsAssessment/routes/RfqDetailsAssesmentInitialFeedback/RfqDetailsAssesmentInitialFeedback'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsAssesmentInitialFeedback'],
});

const AsyncRfqDetailsAssementSupplyAssesment = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsAssessment/routes/RfqDetailsAssementSupplyAssesment/RfqDetailsAssementSupplyAssesment'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsAssementSupplyAssesment'],
});

const AsyncRfqDetailsAssesmentBuildRfq = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsAssessment/routes/RfqDetailsAssesmentBuildRfq/RfqDetailsAssesmentBuildRfq'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsAssesmentBuildRfq'],
});

const AsyncRfqDetailsAssesmentPreviewDeliver = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsAssessment/routes/RfqDetailsAssesmentPreviewDeliver/RfqDetailsAssesmentPreviewDeliver'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsAssesmentPreviewDeliver'],
});

// Manufacturing Opportunity
const AsyncManufacturingRequestForQuoteDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/ManufacturingRequestForQuoteDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['ManufacturingRequestForQuoteDetails'],
});

const AsyncMfgRfqDetailsOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsOverview/MfgRfqDetailsOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsOverview'],
});

const AsyncMfgRfqDetailsItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsItems/MfgRfqDetailsItems'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsItems'],
});

const AsyncMfgRfqDetailsQuote = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsQuote/MfgRfqDetailsQuote'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsQuote'],
});

const AsyncMfgRfqDetailsQuoteGeneral = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsQuote/routes/MfgRfqDetailsQuoteGeneral/MfgRfqDetailsQuoteGeneral'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsQuoteGeneral'],
});

const AsyncMfgRfqDetailsQuoteItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsQuote/routes/MfgRfqDetailsQuoteItems/MfgRfqDetailsQuoteItems'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsQuoteItems'],
});

const AsyncMfgRfqDetailsQuoteTerms = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsQuote/routes/MfgRfqDetailsQuoteTerms/MfgRfqDetailsQuoteTerms'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsQuoteTerms'],
});

const AsyncMfgRfqDetailsQuotePreviewDownload = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsQuote/routes/MfgRfqDetailsQuotePreviewDownload/MfgRfqDetailsQuotePreviewDownload'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsQuotePreviewDownload'],
});

const AsyncMfgRfqDetailsMessages = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsMessages/MfgRfqDetailsMessages'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsMessages'],
});

const AsyncMfgRfqDetailsDocuments = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetails" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsDocuments/MfgRfqDetailsDocuments'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsDocuments'],
});

const AsyncMfgRfqDetailsActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "MfgRfqDetailsActivity" */ './routes/ManufacturingRequestForQuoteDetails/routes/MfgRfqDetailsActivity/MfgRfqDetailsActivity'
    ),
  loading: () => <PageLoader />,
  modules: ['MfgRfqDetailsActivity'],
});

const AsyncMfgRfqDetailsActionItems = Loadable({
  loader: () => import('./routes/ActionItemsTab/MultiDetailsActionItemsTab'),
  loading: () => <PageLoader />,
  modules: ['MultiDetailsActionItemsTab'],
});

// SUPPLY DETAILS
const AsyncSupplyInterestDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestDetails" */ './routes/SupplyInterestDetails/SupplyInterestDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestDetails'],
});

const AsyncSupplyInterestDetailsOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestDetails" */ './routes/SupplyInterestDetails/routes/SupplyInterestDetailsOverview/SupplyInterestDetailsOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestDetails'],
});

const AsyncSupplyInterestDetailsActionItems = Loadable({
  loader: () => import('./routes/ActionItemsTab/MultiDetailsActionItemsTab'),
  loading: () => <PageLoader />,
  modules: ['MultiDetailsActionItemsTab'],
});

const AsyncSupplyInterestDetailsMessaging = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestDetailsMessaging" */ './routes/SupplyInterestDetails/routes/SupplyInterestDetailsMessaging/SupplyInterestDetailsMessaging'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestDetailsMessaging'],
});

const AsyncSupplyInterestDetailsActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestDetailsActivity" */ './routes/SupplyInterestDetails/routes/SupplyInterestDetailsActivity/SupplyInterestDetailsActivity'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestDetailsActivity'],
});

const AsyncSupplyInterestDocuments = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestDocuments" */ './routes/SupplyInterestDetails/routes/SupplyInterestDocuments/SupplyInterestDocuments'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestDocuments'],
});
// END SUPPLY DETAILS

const AsyncSupplyInterestGroupDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestGroupDetails" */ './routes/SupplyInterestGroupDetails/SupplyInterestGroupDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestGroupDetails'],
});

const AsyncSupplyInterestGroupDetailsOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestGroupDetails" */ './routes/SupplyInterestGroupDetails/routes/SupplyInterestGroupDetailsOverview/SupplyInterestGroupDetailsOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestGroupDetails'],
});

const AsyncSupplyInterestGroupDetailsMessaging = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestGroupDetailsMessaging" */ './routes/SupplyInterestGroupDetails/routes/SupplyInterestGroupDetailsMessaging/SupplyInterestGroupDetailsMessaging'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestGroupDetailsMessaging'],
});

const AsyncSupplyInterestGroupDetailsActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestGroupDetailsActivity" */ './routes/SupplyInterestGroupDetails/routes/SupplyInterestGroupDetailsActivity/SupplyInterestGroupDetailsActivity'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestGroupDetailsActivity'],
});

const AsyncSupplyInterestGroupDetailsDocuments = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "SupplyInterestGroupDetailsDocuments" */ './routes/SupplyInterestGroupDetails/routes/SupplyInterestGroupDetailsDocuments/SupplyInterestGroupDetailsDocuments'
    ),
  loading: () => <PageLoader />,
  modules: ['SupplyInterestGroupDetailsDocuments'],
});

const AsyncSupplyInterestGroupDetailsActionItems = Loadable({
  loader: () => import('./routes/ActionItemsTab/MultiDetailsActionItemsTab'),
  loading: () => <PageLoader />,
  modules: ['MultiDetailsActionItemsTab'],
});

const AsyncRfqDetailsMessagingAndActivity = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsMessagingAndActivity/RfqDetailsMessagingAndActivity'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsMessagingAndActivity'],
});

const AsyncRfqDetailsDocuments = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsDocuments/RfqDetailsDocuments'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsDocuments'],
});

const AsyncRfqDetailsOrderInitiation = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsOrderInitiation/RfqDetailsOrderInitiation'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsOrderInitiation'],
});

const AsyncRfqDetailsOrderManagement = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetails" */ './routes/RequestForQuoteDetails/routes/RfqDetailsOrderManagement/RfqDetailsOrderManagement'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsOrderManagement'],
});

const AsyncRfqDetailsActivityFeed = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "RfqDetailsActivityFeed" */ './routes/RequestForQuoteDetails/routes/RfqDetailsActivityFeed/RfqDetailsActivityFeed'
    ),
  loading: () => <PageLoader />,
  modules: ['RfqDetailsActivityFeed'],
});

const AsyncRfqDetailsActionItems = Loadable({
  loader: () => import('./routes/ActionItemsTab/MultiDetailsActionItemsTab'),
  loading: () => <PageLoader />,
  modules: ['MultiDetailsActionItemsTab'],
});

// SETTINGS PAGES
const AsyncSettingsPanel = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "settingsPage" */ './routes/SettingsPanel/SettingsPanel'
    ),
  loading: () => <PageLoader />,
  modules: ['SettingsPanel'],
});

const AsyncManageAttributes = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "ManageAttributes" */ './routes/SettingsPanel/routes/ManageAttributes/ManageAttributes'
    ),
  loading: () => <PageLoader />,
  modules: ['ManageAttributes'],
});

const AsyncAttributeDetails = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AttributeDetails" */ './routes/AttributeDetails/AttributeDetails'
    ),
  loading: () => <PageLoader />,
  modules: ['AttributeDetails'],
});

const AsyncAttributeDetailsOverview = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AttributeDetails" */ './routes/AttributeDetails/routes/AttributeDetailsOverview/AttributeDetailsOverview'
    ),
  loading: () => <PageLoader />,
  modules: ['AttributeDetails'],
});

const AsyncAttributeDetailsItems = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "AttributeDetails" */ './routes/AttributeDetails/routes/AttributeDetailsItems/AttributeDetailsItems'
    ),
  loading: () => <PageLoader />,
  modules: ['AttributeDetailsItems'],
});

const AsyncAccountSettingsBusiness = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "settingsPage" */ './routes/AccountSettingsBusiness/AccountSettingsBusiness'
    ),
  loading: () => <PageLoader />,
  modules: ['AccountSettingsBusiness'],
});

const AsyncAccountSettingsPermissions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "settingsPage" */ './routes/AccountSettingsPermissions/AccountSettingsPermissions'
    ),
  loading: () => <PageLoader />,
  modules: ['AccountSettingsPermissions'],
});

const AsyncLocationsOfInterestSettings = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "settingsPage" */ './routes/LocationsOfInterestSettings/LocationsOfInterestSettings'
    ),
  loading: () => <PageLoader />,
  modules: ['LocationsOfInterestSettings'],
});

const AsyncEmailPreferences = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "settingsPage" */ './routes/EmailPreferences/EmailPreferences'
    ),
  loading: () => <PageLoader />,
  modules: ['EmailPreferences'],
});

const Routes = [
  {
    path: ['/landing', '/m/*', '/landing/*'],
    exact: true,
    component: AsyncPageLanding,
    loadData: () => ({}),
  },
  {
    path: ['/welcome-suppliers', '/welcome-suppliers/*'],
    exact: true,
    component: AsyncPageWelcomeSuppliers,
    loadData: () => ({}),
  },
  {
    path: ['/request-for-quote', '/request-for-quote/m/*'],
    exact: true,
    component: AsyncRequestForQuoteLanding,
    loadData: () => ({}),
  },
  {
    path: ['/sellers', '/sellers/m/*'],
    exact: true,
    component: AsyncPageForSellers,
    loadData: () => ({}),
  },
  {
    path: ['/buyers', '/buyers/m/*'],
    exact: true,
    component: AsyncPageForBuyers,
    loadData: () => ({}),
  },
  {
    path: '/p/:slug/:id',
    exact: true,
    component: AsyncProductPages,
    loadData: () => import('routes/Products/ProductsLoadData'),
  },
  {
    path: ['/l/:slug/:id', '/l/:slug/:id/*'],
    exact: true,
    component: AsyncListingPages,
    loadData: () => import('routes/Products/ListingsLoadData'),
  },
  {
    path: ['/technical-resources'],
    exact: true,
    component: AsyncTechnicalResources,
    routes: [
      {
        path: ['/technical-resources'],
        exact: true,
        component: AsyncTechnicalResources,
      },
    ],
  },
  {
    path: '/tags/:tag',
    exact: true,
    component: AsyncBlogList,
    breadcrumb: (s) => <span>{startCase(s.match.path)}</span>,
  },
  {
    path: [
      '/blog',
      '/technical-content',
      '/industry-news',
      '/articles',
      '/company-news',
      '/video',
      '/audio',
    ],
    exact: true,
    component: AsyncBlogList,
    breadcrumb: (s) => <span>{startCase(s.match.path)}</span>,
    loadData: () => ({}),
  },
  {
    path: [
      '/blog/:slug/:id',
      '/technical-content/:slug/:id',
      '/industry-news/:slug/:id',
      '/articles/:slug/:id',
      '/company-news/:slug/:id',
      '/video/:slug/:id',
      '/audio/:slug/:id',
    ],
    exact: true,
    component: AsyncPageBlogPostContent,
    breadcrumb: (s) => <span>{startCase(s.match.params.slug)}</span>,
    loadData: () => import('routes/BlogPostContent/BlogPostContentLoadData'),
  },
  {
    path: '/register',
    exact: true,
    component: AsyncPageCreateAccount,
  },
  {
    path: '/account-created',
    exact: true,
    component: AsyncAccountCreated,
    loadData: () => ({}),
  },
  {
    path: '/subscriptions',
    exact: true,
    component: AsyncAccountSubscriptions,
    loadData: () => ({}),
  },
  {
    path: '/login',
    exact: true,
    component: AsyncLogin,
    loadData: () => ({}),
  },
  {
    path: '/account/confirmation',
    exact: true,
    component: AsyncConfirmEmail,
  },
  {
    path: '/recover-password',
    exact: true,
    component: AsyncCantLogin,
    loadData: () => ({}),
  },
  {
    path: '/account/reset',
    exact: true,
    component: AsyncResetPassword,
    loadData: () => ({}),
  },

  {
    path: ['/c', '/c/*', '/c/*/*', '/c/*/*/*'],
    component: AsyncPageCategoriesWrapper,
    routes: [
      {
        path: [
          '/c/:catType/:alloyGroupSlug/:alloySlug',
          '/c/:catType/:alloyGroupSlug',
          '*',
        ],
        exact: true,
        component: AsyncPageCategoryDetails,
        loadData: () => import('routes/Categories/CategoriesLoadData'),
      },
    ],
  },

  {
    path: ['/', '/global-warehouse/*', '/global-warehouse'],
    component: AsyncPageGlobalWarehouse,
    exact: true,
    routes: [
      {
        path: ['/global-warehouse/*', '/global-warehouse'],
        exact: true,
        component: AsyncPageGlobalWarehouse,
      },
    ],
  },

  {
    path: ['/product-guide/*', '/product-guide'],
    component: AsyncPageProductGuide,
    exact: true,
    routes: [
      {
        path: ['/product-guide/*', '/product-guide'],
        exact: true,
        component: AsyncPageProductGuide,
      },
    ],
  },

  {
    path: ['/s/*', '/s'],
    component: AsyncPageSearchWrapper,
    exact: true,
    routes: [
      {
        path: ['/s/listings/*', '/s/listings'],
        exact: true,
        component: AsyncPageSearchListings,
      },
      {
        exact: true,
        component: AsyncPageSearchDetails,
      },
    ],
  },

  {
    path: '/why-pipesearch',
    exact: true,
    component: AsyncPageCompanyWhyPipeSearch,
    loadData: () => ({}),
  },

  {
    path: '/terms',
    exact: true,
    component: hardRedirectRoute('terms.html'),
    loadData: () => ({}),
  },
  {
    path: '/privacy-policy',
    exact: true,
    component: hardRedirectRoute('privacy-policy.html'),
    loadData: () => ({}),
  },
  {
    path: '/contact',
    exact: true,
    component: AsyncPageContact,
    loadData: () => ({}),
  },
  {
    path: '/not-found',
    component: AsyncPageError,
    loadData: () => ({}),
  },
  {
    path: '/find',
    exact: true,
    component: requireAuth(AsyncPageInventory),
    loadData: () => ({}),
  },
  {
    path: '/dashboard',
    component: requireFacilitatorOrAdminForBeta(AsyncAdminPanel),
    routes: [
      {
        path: ['/dashboard/conversations', '/dashboard/conversations/:id'],
        component: requireAuth(AsyncPageConversations),
        exact: true,
      },
      {
        path: ['/dashboard/partner-manager-conversation'],
        component: requireAuth(AsyncPagePartnerManagerConversation),
        exact: true,
      },
      {
        path: [
          '/dashboard/opportunities',
          '/dashboard/opportunities/my-accounts',
          '/dashboard/opportunities/manufacturing-rfqs',
          '/dashboard/opportunities/manufacturing-rfqs/m/*',
          '/dashboard/opportunities/m/*',
        ],
        component: requireFacilitatorOrAdmin(AsyncPageOpportunities),
        exact: true,
      },
      {
        path: ['/dashboard/companies', '/dashboard/companies/my-accounts'],
        component: requireFacilitatorOrPartner(AsyncPageCompanies),
        exact: true,
      },
      {
        path: [
          '/dashboard/supply/items',
          '/dashboard/supply/groups',
          '/dashboard/supply/items/*',
          '/dashboard/supply/groups/*',
          '/dashboard/supply',
          '/dashboard/supply/all',
          '/dashboard/supply/finder-leads',
          '/dashboard/supply/internal-leads',
          '/dashboard/supply/opportunities',
          '/dashboard/supply/offers',
          '/dashboard/supply/acquired',
          '/dashboard/supply/market-inventory',
          '/dashboard/supply/archive',
          '/dashboard/supply/m/*',
        ],
        component: requireAuth(AsyncPageSupplyInterests),
        routes: [
          {
            path: [
              '/dashboard/supply',
              '/dashboard/supply/groups',
              '/dashboard/supply/m/*',
            ],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsGroupsAgGrid),
          },
          {
            path: ['/dashboard/supply/all'],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsGroupsAgGrid),
          },
          {
            path: ['/dashboard/supply/finder-leads'],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsGroupsAgGrid),
          },
          {
            path: ['/dashboard/supply/internal-leads'],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsGroupsAgGrid),
          },
          {
            path: ['/dashboard/supply/opportunities'],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsGroupsAgGrid),
          },
          {
            path: ['/dashboard/supply/offers'],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsGroupsAgGrid),
          },
          {
            path: ['/dashboard/supply/acquired'],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsGroupsAgGrid),
          },
          {
            path: ['/dashboard/supply/market-inventory'],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsGroupsAgGrid),
          },
          {
            path: ['/dashboard/supply/archive'],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsGroupsAgGrid),
          },
          {
            path: ['/dashboard/supply/items', '/dashboard/supply/m/*'],
            exact: true,
            component: requireAuth(AsyncPageSupplyInterestsItems),
          },
        ],
        exact: true,
      },

      {
        path: [
          '/dashboard/market-comparables',
          '/dashboard/market-comparables/request-history',
          '/dashboard/market-comparables/order-history',
          '/dashboard/market-comparables/purchase-history',
          '/dashboard/market-comparables/quote-history',
          '/dashboard/market-comparables/scrap-history',
        ],
        component: requireAuth(AsyncMarketComparablesMain),
        routes: [
          {
            path: ['/dashboard/market-comparables'],
            exact: true,
            component: requireAuth(AsyncMarketComparables),
          },
          {
            path: ['/dashboard/market-comparables/request-history'],
            exact: true,
            component: requireAuth(AsyncRequestHistory),
          },
          {
            path: ['/dashboard/market-comparables/order-history'],
            exact: true,
            component: requireAuth(AsyncOrderHistory),
          },
          {
            path: ['/dashboard/market-comparables/purchase-history'],
            exact: true,
            component: requireAuth(AsyncPurchaseHistory),
          },
          {
            path: ['/dashboard/market-comparables/quote-history'],
            exact: true,
            component: requireAuth(AsyncQuoteHistory),
          },
          {
            path: ['/dashboard/market-comparables/scrap-history'],
            exact: true,
            component: requireAuth(AsyncScrapHistory),
          },
        ],
        exact: true,
      },
      {
        path: [
          '/dashboard/market-comparables/:id',
          '/dashboard/market-comparables/:id/*',
        ],
        component: requireFacilitator(AsyncMarketComparablesDetails),
        exact: true,
        routes: [
          {
            path: [
              '/dashboard/market-comparables/:id',
              '/dashboard/market-comparables/:id/overview',
            ],
            exact: true,
            component: requireAuth(AsyncMarketComparablesDetailsOverview),
          },
          {
            path: ['/dashboard/market-comparables/:id/activity'],
            exact: true,
            component: requireAuth(AsyncMarketComparablesDetailsActivity),
          },
          {
            path: ['/dashboard/market-comparables/:id/messages'],
            exact: true,
            component: requireAuth(AsyncMarketComparablesDetailsMessages),
          },
          {
            path: ['/dashboard/market-comparables/:id/documents'],
            exact: true,
            component: requireAuth(AsyncMarketComparablesDetailsDocuments),
          },
        ],
      },
      {
        path: ['/dashboard/content-editor'],
        component: AsyncPageBlogEditor,
        exact: true,
      },
      {
        path: ['/dashboard/content-editor/:id'],
        component: AsyncPageBlogEditor,
        exact: true,
      },
      {
        path: [
          '/dashboard/users/my-accounts',
          '/dashboard/users/partner-accounts',
          '/dashboard/users/networking',
          '/dashboard/users/networking/m/*',
          '/dashboard/users',
        ],
        component: requireFacilitatorOrAdminOrPartner(AsyncPageCustomers),
        exact: true,
        routes: [
          {
            path: ['/dashboard/users/my-accounts', '/dashboard/users'],
            component: requireFacilitatorOrAdminOrPartner(
              AsyncPageCustomersAgGrid
            ),
            exact: true,
          },
          {
            path: ['/dashboard/users/partner-accounts', '/dashboard/users'],
            component: requireFacilitatorOrAdminOrPartner(
              AsyncPagePartnerCustomersTable
            ),
            exact: true,
          },
          {
            path: ['/dashboard/users/networking'],
            component: requireFacilitatorOrAdmin(AsyncPageCustomersNetworking),
            exact: true,
          },
          {
            path: ['/dashboard/users/networking/m/*'],
            component: requireFacilitatorOrAdmin(AsyncPageCustomersNetworking),
          },
        ],
      },
      {
        path: ['/dashboard/campaigns', '/dashboard/campaigns/m/*'],
        component: requireFacilitatorOrAdmin(AsyncPageCampaigns),
        exact: true,
      },
      {
        path: ['/dashboard/attributes/:id', '/dashboard/attributes/:id/*'],
        component: requireFacilitatorOrAdmin(AsyncAttributeDetails),
        exact: true,
        routes: [
          {
            path: [
              '/dashboard/attributes/:id/details',
              '/dashboard/attributes/:id',
            ],
            exact: true,
            component: requireAuth(AsyncAttributeDetailsOverview),
          },
          {
            path: ['/dashboard/attributes/:id/items'],
            exact: true,
            component: requireAuth(AsyncAttributeDetailsItems),
          },
        ],
      },

      {
        path: ['/dashboard/campaigns/:id', '/dashboard/campaigns/:id/*'],
        component: requireFacilitator(AsyncPageCampaignDetails),
        exact: true,

        routes: [
          {
            path: [
              '/dashboard/campaigns/:id/details',
              '/dashboard/campaigns/:id',
            ],
            exact: true,
            component: requireAuth(AsyncPageCampaignDetailsOverview),
          },
          {
            path: ['/dashboard/campaigns/:id/recipients'],
            exact: true,
            component: requireAuth(AsyncPageCampaignRecipients),
          },
          {
            path: ['/dashboard/campaigns/:id/preview'],
            exact: true,
            component: requireAuth(AsyncPageCampaignPreview),
          },
          {
            path: ['/dashboard/campaigns/:id/activity'],
            exact: true,
            component: requireAuth(AsyncPageCampaignActivity),
          },
          {
            path: ['/dashboard/campaigns/:id/messages'],
            exact: true,
            component: requireAuth(AsyncPageCampaignMessaging),
          },
        ],
      },
      {
        path: [
          '/dashboard/project-management',
          '/dashboard/project-management/engagements',
          '/dashboard/project-management/interactions',
          '/dashboard/project-management/action-items',
          '/dashboard/project-management/my-action-items',
        ],
        component: requireFacilitatorOrPartner(AsyncPageProjectManagement),
        exact: true,
        routes: [
          {
            path: ['/dashboard/project-management/engagements'],
            exact: true,
            component: requireAuth(AsyncPageEngagementsList),
          },
          {
            path: ['/dashboard/project-management/interactions'],
            exact: true,
            component: requireAuth(AsyncPageInteractionsList),
          },
          {
            path: ['/dashboard/project-management/action-items'],
            exact: true,
            component: requireAuth(AsyncPageActionItemsList),
          },
          {
            path: ['/dashboard/project-management/my-action-items'],
            exact: true,
            component: requireAuth(AsyncPageActionItemsList),
          },
        ],
      },
      {
        path: ['/dashboard/listings/:id', '/dashboard/listings/:id/*'],
        component: requireFacilitator(AsyncPageListingDetail),
        exact: true,
        routes: [
          {
            path: ['/dashboard/listings/:id/preview'],
            exact: true,
            component: requireAuth(AsyncPageListingOverview),
          },
          {
            path: ['/dashboard/listings/:id/performance'],
            exact: true,
            component: requireAuth(AsyncPageListingPerformance),
          },
          {
            path: ['/dashboard/listings/:id/campaigns'],
            exact: true,
            component: requireAuth(AsyncPageListingCampaigns),
          },
          {
            path: ['/dashboard/listings/:id/messages'],
            exact: true,
            component: requireAuth(AsyncPageListingMessaging),
          },
        ],
      },

      {
        path: ['/dashboard/engagements/:id', '/dashboard/engagements/:id/*'],
        component: requireFacilitator(AsyncPageEngagementDetail),
        exact: true,
        routes: [
          {
            path: ['/dashboard/engagements/:id/interactions'],
            exact: true,
            component: requireAuth(AsyncPageEngagementInteractions),
          },
          {
            path: ['/dashboard/engagements/:id/action-items'],
            exact: true,
            component: requireAuth(AsyncPageEngagementActionItems),
          },
          {
            path: ['/dashboard/engagements/:id/messages'],
            exact: true,
            component: requireAuth(AsyncPageEngagementMessaging),
          },
          {
            path: ['/dashboard/engagements/:id/documents'],
            exact: true,
            component: requireAuth(AsyncPageEngagementDocuments),
          },
        ],
      },
      {
        path: ['/dashboard/companies/:id', '/dashboard/companies/:id/*'],
        component: requireFacilitatorOrPartner(AsyncPageCompanyDetails),
        exact: true,

        routes: [
          {
            path: [
              '/dashboard/companies/:id/details',
              '/dashboard/companies/:id',
            ],
            exact: true,
            component: requireAuth(AsyncPageCompanyDetailsOverview),
          },
          {
            path: ['/dashboard/companies/:id/supply'],
            exact: true,
            component: requireAuth(AsyncPageCompanySupply),
          },
          {
            path: ['/dashboard/companies/:id/demand'],
            exact: true,
            component: requireAuth(AsyncPageCompanyDemand),
          },
          {
            path: ['/dashboard/companies/:id/wishlist'],
            exact: true,
            component: requireAuth(AsyncPageCompanyWishlist),
          },
          {
            path: ['/dashboard/companies/:id/employees'],
            exact: true,
            component: requireAuth(AsyncPageCustomersAgGrid),
          },
          {
            path: ['/dashboard/companies/:id/messages'],
            exact: true,
            component: requireAuth(AsyncPageCompanyMessaging),
          },
          {
            path: ['/dashboard/companies/:id/activity'],
            exact: true,
            component: requireAuth(AsyncPageCompanyActivity),
          },
          {
            path: ['/dashboard/companies/:id/engagements'],
            exact: true,
            component: requireAuth(AsyncPageCompanyEngagements),
          },
          {
            path: ['/dashboard/companies/:id/action-items'],
            exact: true,
            component: requireAuth(AsyncPageCompanyActionItems),
          },
        ],
      },

      {
        path: '/dashboard/users/:id',
        component: requireFacilitatorOrAdminOrPartner(AsyncPageCustomerDetails),
        routes: [
          {
            path: ['/dashboard/users/:id/analytics'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsAnalytics),
          },
          {
            path: ['/dashboard/users/:id/profile'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsOverview),
          },
          {
            path: ['/dashboard/users/:id/wishlist'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsWishlist),
          },
          {
            path: ['/dashboard/users/:id/action-items'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsActionItems),
          },
          {
            path: ['/dashboard/users/:id/messages'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsMessages),
          },
          {
            path: ['/dashboard/users/:id/engagements'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsEngagements),
          },
          {
            path: ['/dashboard/users/:id/supply'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsSupply),
          },
          {
            path: ['/dashboard/users/:id/demand'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsDemand),
          },
          {
            path: [
              '/dashboard/users/:id/activity',
              '/dashboard/users/:id/related-activity',
            ],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsActivity),
          },
          {
            path: ['/dashboard/users/:id/interaction'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsInteraction),
          },
          {
            path: ['/dashboard/users/:id'],
            exact: true,
            component: requireAuth(AsyncPageCustomerDetailsOverview),
          },
        ],
      },

      {
        path: '/dashboard/inventory',
        component: requireFacilitatorOrAdmin(AsyncPageInventory),
        exact: true,
      },
      {
        path: '/dashboard/messages',
        component: requireAuth(AsyncPageMessages),
        exact: true,
      },
      {
        path: '/dashboard/listings',
        component: requireAuth(AsyncPageDashboardListings),
        exact: true,
      },
      {
        path: '/dashboard/products',
        component: requireAuth(AsyncPageProductsManagement),
        exact: true,
      },
      {
        path: ['/dashboard/my-requests', '/dashboard/my-requests/m/*'],
        exact: true,
        component: requireAuth(AsyncPageMyRequests),
      },
      {
        path: ['/dashboard/my-requests/:id', '/dashboard/opportunities/:id'],
        component: requireAuth(AsyncRequestForQuoteDetails),
        routes: [
          {
            path: [
              '/dashboard/my-requests/:id/information',
              '/dashboard/my-requests/:id',
              '/dashboard/my-requests/:id/information/*',
              '/dashboard/my-requests/:id/m/*',

              '/dashboard/opportunities/:id/information',
              '/dashboard/opportunities/:id',
              '/dashboard/opportunities/:id/information/*',
              '/dashboard/opportunities/:id/m/*',
            ],
            exact: true,
            component: requireAuth(AsyncRfqDetailsInformation),
            data: { name: 'Information' },
          },
          {
            path: ['/dashboard/opportunities/:id/assessment'],
            component: requireFacilitatorOrAdmin(AsyncRfqDetailsAssessment),
            routes: [
              {
                path: [
                  '/dashboard/opportunities/:id/assessment/initial-feedback',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncRfqDetailsAssesmentInitialFeedback
                ),
              },
              {
                path: ['/dashboard/opportunities/:id/assessment/quote-items'],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncRfqDetailsAssementSupplyAssesment
                ),
              },
              {
                path: [
                  '/dashboard/opportunities/:id/assessment/quote-items/rfq-item/:itemId',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncRfqDetailsAssementSupplyAssesment
                ),
              },
              {
                path: [
                  '/dashboard/opportunities/:id/assessment/quote-items/rfq-item/:itemId/quote-item/:quoteItemId',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncRfqDetailsAssementSupplyAssesment
                ),
              },
              {
                path: ['/dashboard/opportunities/:id/assessment/build-rfq'],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncRfqDetailsAssesmentBuildRfq
                ),
              },
              {
                path: [
                  '/dashboard/opportunities/:id/assessment/preview-deliver',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncRfqDetailsAssesmentPreviewDeliver
                ),
              },
            ],
          },
          {
            path: [
              '/dashboard/my-requests/:id/items',
              '/dashboard/opportunities/:id/items',
            ],
            exact: true,
            component: requireAuth(AsyncRfqDetailsItems),
          },
          {
            path: [
              '/dashboard/my-requests/:id/messages',
              '/dashboard/opportunities/:id/messages',
            ],
            exact: true,
            component: requireAuth(AsyncRfqDetailsMessagingAndActivity),
          },
          {
            path: [
              '/dashboard/my-requests/:id/documents',
              '/dashboard/opportunities/:id/documents',
            ],
            exact: true,
            component: requireAuth(AsyncRfqDetailsDocuments),
          },
          {
            path: [
              '/dashboard/my-requests/:id/order-initiation',
              '/dashboard/opportunities/:id/order-initiation',
            ],
            exact: true,
            component: requireAuth(AsyncRfqDetailsOrderInitiation),
          },
          {
            path: [
              '/dashboard/my-requests/:id/order-management',
              '/dashboard/opportunities/:id/order-management',
            ],
            exact: true,
            component: requireAuth(AsyncRfqDetailsOrderManagement),
          },
          {
            path: [
              '/dashboard/my-requests/:id/activity',
              '/dashboard/opportunities/:id/activity',
            ],
            exact: true,
            component: requireAuth(AsyncRfqDetailsActivityFeed),
          },
          {
            path: ['/dashboard/opportunities/:id/actionitems'],
            exact: true,
            component: requireAuth(AsyncRfqDetailsActionItems),
          },
        ],
      },
      {
        path: ['/dashboard/manufacturing-opportunities/:id'],
        component: requireAuth(AsyncManufacturingRequestForQuoteDetails),
        routes: [
          {
            path: [
              '/dashboard/manufacturing-opportunities/:id/overview',
              '/dashboard/manufacturing-opportunities/:id',
              '/dashboard/manufacturing-opportunities/:id/overview/*',
              '/dashboard/manufacturing-opportunities/:id/m/*',
            ],
            exact: true,
            component: requireAuth(AsyncMfgRfqDetailsOverview),
            data: { name: 'Overview' },
          },
          {
            path: ['/dashboard/manufacturing-opportunities/:id/quote'],
            component: requireFacilitatorOrAdmin(AsyncMfgRfqDetailsQuote),
            routes: [
              {
                path: [
                  '/dashboard/manufacturing-opportunities/:id/quote/general',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncMfgRfqDetailsQuoteGeneral
                ),
              },
              {
                path: [
                  '/dashboard/manufacturing-opportunities/:id/quote/items',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncMfgRfqDetailsQuoteItems
                ),
              },
              {
                path: [
                  '/dashboard/manufacturing-opportunities/:id/quote/items/rfq-item/:itemId',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncMfgRfqDetailsQuoteItems
                ),
              },
              {
                path: [
                  '/dashboard/manufacturing-opportunities/:id/quote/items/rfq-item/:itemId/quote-item/:quoteItemId',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncMfgRfqDetailsQuoteItems
                ),
              },
              {
                path: [
                  '/dashboard/manufacturing-opportunities/:id/quote/terms',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncMfgRfqDetailsQuoteTerms
                ),
              },
              {
                path: [
                  '/dashboard/manufacturing-opportunities/:id/quote/preview-download',
                ],
                exact: true,
                component: requireFacilitatorOrAdmin(
                  AsyncMfgRfqDetailsQuotePreviewDownload
                ),
              },
            ],
          },
          {
            // linked from Priced Inventory -> View Comparison
            path: ['/dashboard/manufacturing-opportunities/:id/items'],
            exact: true,
            component: requireAuth(AsyncMfgRfqDetailsItems),
          },
          {
            path: ['/dashboard/manufacturing-opportunities/:id/activity'],
            exact: true,
            component: requireAuth(AsyncMfgRfqDetailsActivity),
          },
          {
            path: ['/dashboard/manufacturing-opportunities/:id/action-items'],
            exact: true,
            component: requireAuth(AsyncMfgRfqDetailsActionItems),
          },
          {
            path: ['/dashboard/manufacturing-opportunities/:id/messages'],
            exact: true,
            component: requireAuth(AsyncMfgRfqDetailsMessages),
          },
          {
            path: ['/dashboard/manufacturing-opportunities/:id/documents'],
            exact: true,
            component: requireAuth(AsyncMfgRfqDetailsDocuments),
          },
          /* {
            path: [
              '/dashboard/opportunities/:id/order-initiation',
            ],
            exact: true,
            component: requireAuth(AsyncRfqDetailsOrderInitiation),
          }, */
          /* {
            path: [
              '/dashboard/opportunities/:id/order-management',
            ],
            exact: true,
            component: requireAuth(AsyncRfqDetailsOrderManagement),
          }, */
        ],
      },
      {
        path: [
          '/dashboard/my-inventory/group/:id',
          '/dashboard/my-inventory/group/:id/*',
          '/dashboard/supply/group/:id',
          '/dashboard/supply/group/:id/*',
        ],
        component: requireAuth(AsyncSupplyInterestGroupDetails),
        routes: [
          {
            path: [
              '/dashboard/supply/group/:id/details',
              '/dashboard/supply/group/:id',
              '/dashboard/my-inventory/group/:id/details',
              '/dashboard/my-inventory/group/:id',
            ],
            exact: true,
            component: requireAuth(AsyncSupplyInterestGroupDetailsOverview),
          },
          {
            path: [
              '/dashboard/supply/group/:id/activity',
              '/dashboard/my-inventory/group/:id/activity',
            ],
            exact: true,
            component: requireAuth(AsyncSupplyInterestGroupDetailsActivity),
          },
          {
            path: [
              '/dashboard/supply/group/:id/messages',
              '/dashboard/my-inventory/group/:id/messages',
            ],
            exact: true,
            component: requireAuth(AsyncSupplyInterestGroupDetailsMessaging),
          },
          {
            path: [
              '/dashboard/supply/group/:id/documents',
              '/dashboard/my-inventory/group/:id/documents',
            ],
            exact: true,
            component: requireAuth(AsyncSupplyInterestGroupDetailsDocuments),
          },
          {
            path: ['/dashboard/supply/group/:id/actionitems'],
            exact: true,
            component: requireAuth(AsyncSupplyInterestGroupDetailsActionItems),
          },
        ],
      },

      {
        path: [
          '/dashboard/inventory-item/:id',
          '/dashboard/inventory-item/:id/*',
          '/dashboard/supply-item/:id',
          '/dashboard/supply-item/:id/*',
          '/dashboard/my-inventory/:id',
          '/dashboard/my-inventory/:id/*',
          '/dashboard/supply/:id',
          '/dashboard/supply/:id/*',
        ],
        component: requireAuth(AsyncSupplyInterestDetails),
        routes: [
          {
            path: [
              '/dashboard/supply-item/:id/details',
              '/dashboard/supply-item/:id',
              '/dashboard/inventory-item/:id/details',
              '/dashboard/inventory-item/:id',

              '/dashboard/supply/:id/details',
              '/dashboard/supply/:id',
              '/dashboard/my-inventory/:id/details',
              '/dashboard/my-inventory/:id',
            ],
            exact: true,
            component: requireAuth(AsyncSupplyInterestDetailsOverview),
          },
          {
            path: ['/dashboard/supply-item/:id/actionitems'],
            exact: true,
            component: requireAuth(AsyncSupplyInterestDetailsActionItems),
          },
          {
            path: [
              '/dashboard/supply-item/:id/activity',
              '/dashboard/inventory-item/:id/activity',
              '/dashboard/supply/:id/activity',
              '/dashboard/my-inventory/:id/activity', // deprecating
            ],
            exact: true,
            component: requireAuth(AsyncSupplyInterestDetailsActivity),
          },
          {
            path: [
              '/dashboard/supply-item/:id/messages',
              '/dashboard/inventory-item/:id/messages',
              '/dashboard/supply/:id/messages',
              '/dashboard/my-inventory/:id/messages', // deprecating
            ],
            exact: true,
            component: requireAuth(AsyncSupplyInterestDetailsMessaging),
          },
          {
            path: [
              '/dashboard/supply-item/:id/documents',
              '/dashboard/inventory-item/:id/documents',
              '/dashboard/supply/:id/documents',
              '/dashboard/my-inventory/:id/documents', // deprecating
            ],
            exact: true,
            component: requireAuth(AsyncSupplyInterestDocuments),
          },
        ],
      },
      {
        path: '/dashboard/wish-list',
        component: requireAuth(AsyncPageWishList),
        exact: true,
      },
      {
        path: ['/dashboard/activity', '/dashboard/all-activity'],
        component: requireAuth(AsyncPageActivity),
        exact: true,
      },
      {
        path: ['/dashboard/activity', '/dashboard/technical'],
        component: requireAuth(AsyncPageTechnicalResources),
        exact: true,
      },
      {
        component: requireAuth(AsyncDashboard),
        path: [
          '/dashboard/attributes',
          '/dashboard/performance',
          '/dashboard/recent',
          '/dashboard',
        ],
        routes: [
          {
            path: ['/dashboard/attributes'],
            exact: true,
            component: requireAuth(AsyncDashboardFacilitatorAttributes),
          },
          {
            path: ['/dashboard/performance'],
            exact: true,
            component: requireAuth(AsyncDashboardFacilitatorPerformance),
          },
          {
            path: ['/dashboard/recent'],
            exact: true,
            component: requireAuth(AsyncDashboardRecentItems),
          },
        ],
      },
    ],
  },
  {
    path: '/settings',
    component: requireAuth(AsyncSettingsPanel),
    routes: [
      {
        path: '/settings/attributes',
        component: requireFacilitatorOrAdmin(AsyncManageAttributes),
        exact: true,
      },
      {
        path: '/settings/account',
        component: requireAuth(AsyncEmailPreferences),
        exact: true,
      },
      {
        path: '/settings/locations-of-interest',
        component: requireAuth(AsyncLocationsOfInterestSettings),
        exact: true,
      },
      {
        path: '/settings/business',
        component: requireAuth(AsyncAccountSettingsBusiness),
        exact: true,
      },
      {
        path: '/settings/permissions',
        component: requireAuth(AsyncAccountSettingsPermissions),
        exact: true,
      },
    ],
  },
  {
    component: error404(AsyncPageError),
  },
];

export default Routes;
